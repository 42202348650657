<template>
    <el-container class="h-100">
    <meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0">
        <div class="h-100 w-500">
            <header class="h5_header">
                <h1>{{ h5Data.doc_header.doc_title }}</h1>
                <p>报告于{{ h5Data.doc_header.doc_date }}由舆情管理系统生成</p>
            </header>
            <div class="h5_nav  mar-t-15">
                <a @click="handleClick('gs')" :class="{active: activeIndex === 'gs'}">概述</a>
                <a @click="handleClick('zdxxzy')" :class="{active: activeIndex === 'zdxxzy'}">重点信息摘要</a>
            </div>
            <div v-if="activeIndex === 'gs'" class="gs_cont">
                <div class="h5_card">
                    <h3>一、概述</h3>
                    <p>{{ h5Data.diagram_info1_header }}</p>
                </div>
                <div class="t-c">
                    <img src="@/assets/images/common/banner@3x.png" width="100%">
                </div>
            </div>
            <div v-if="activeIndex === 'zdxxzy'" class="zdxxzy_cont">
                <ul>
                    <li v-for="(item, index) in h5Data.diagram_info2_list" :key="index" class="h5_card">
                            <h4>
                                {{ index + 1 }}.
                                <span class="fmg" v-if="item.emotion === 'positive'">非敏感</span>
                                <span class="mg" v-else>敏感</span>
                                {{ item.title }}
                            </h4>
                        <p class="fbsj">
                            <span>发布时间：{{ item.published }}</span>
                            <span>相似数：{{ item.similar_num }}</span>
                            <span>来源：{{ item.source_name }}</span>
                        </p>
                        <p class="summary">正文：{{ item.content }}</p>
                        <a class="more" :href="item.source_url" target="_blank">查看原文 &gt;&gt;</a>
                    </li>
                </ul>
                <div class="t-c xh button_group hide">
                    <el-button type="primary">查看更多</el-button>
                    <el-button type="primary" class="hide">暂无更多</el-button>
                </div>
            </div>
            <footer class="h5_footer">数知舆情管理系统 版权所有</footer>
        </div>
    </el-container>
</template>
<script>
import { detail } from '@/api/briefing'
export default {
  name: "brief-abstract-h5",
  data() {
    return {
      activeIndex: "gs",
      h5Data: {}
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData() {
      const {id} = this.$route.query;
      if (!id) {
        this.$message.error('不存在该简报');
        return false;
      }
      detail({id: id}).then(res => {
        const {state, data} = res.data;
        if (state) {
          this.h5Data = JSON.parse(data.data);
        } else {
          this.$message.error(res.data.error);
        }
      }).catch(err => {
        window.console.error(err)
      })
    },
    handleClick(index) {
      this.activeIndex = index
    }
  }
}
</script>
<style scoped>
.w-500{width: 100%;}
@media screen and (min-width:500px) {
  .w-500{width:500px;margin:0 auto;}
}
.h5_header{background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);width:100%;text-align: center;overflow: hidden;color:#fff;padding:10px 0;}
.h5_header h1{font-size:15px;line-height:40px;color:#fff;}
.h5_header p{line-height:20px;font-size:12px;color:#fff;}
.h5_nav{margin:15px 15px 0;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.h5_nav a{height:30px;line-height:30px;background:#e5e5e5;font-size:12px;border-radius:15px;color:rgba(0,0,0,.5);display: inline-block;padding:0 80px;cursor: pointer;}
.h5_nav a.active{color:#fff;background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);}
.gs_cont,.zdxxzy_cont{padding:15px;}
.h5_card{background-color: #ffffff;box-shadow:0px 2px 8px 0px rgba(194,194,194,0.2);border-radius:3px;padding: 15px;}
.h5_card h3,.h5_card p{font-size:12px;line-height:24px;color:rgba(1,1,1,.8)}
.h5_card h3{font-weight:600;}
/*重点信息摘要*/
.zdxxzy_cont li{margin-bottom: 15px}
.zdxxzy_cont p.zyxx{margin:0 15px;font-size:12px;line-height:30px;color:rgb(0,0,0);}
.zdxxzy_cont h4{font-size:12px;line-height:24px;color:rgb(0,0,0);font-weight:600;max-height:120px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;}
.zdxxzy_cont h4 .mg,.zdxxzy_cont h4 .fmg{background: #f00;border-radius:3px;font-size:12px;color: #fff;margin:0 5px;line-height:22px;float:none;display:inline-block;}
.zdxxzy_cont h4 .fmg{background:#555DFE;}
.zdxxzy_cont .cor_006{color:#0068b7;}
.zdxxzy_cont .fbsj{line-height:30px;color:rgba(0,0,0,.8);font-size:12px;}
.zdxxzy_cont .fbsj span{padding-right:10px;}
.zdxxzy_cont .summary{background:rgba(191,191,191,.1);padding:15px;font-size:12px;line-height:18px;color:rgba(0,0,0,.6);}
.zdxxzy_cont a{text-align: center;font-size:12px;line-height:30px;color:#000;width: 100%;display: block;}
.zdxxzy_cont .xh{font-size:12px;line-height:30px;padding: 10px 0 0;}
.h5_footer{text-align: center;font-size:12px;line-height:60px;color:rgba(0,0,0,.26);}
</style>
